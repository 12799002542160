import React, { useState } from 'react';
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    IonButtons,
    IonButton,
    IonTitle,
    IonBackButton
} from '@ionic/react';
import './Whatsapp-message.scss';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

interface WhatsappMessageProps { }

const WhatsappMessage: React.FC<WhatsappMessageProps> = () => {
    const [phoneNumber, setPhoneNumber] = useState('');

    const sayHello = async () => {
        if (phoneNumber !== '') {
            // alert('Hello!' + phoneNumber); //
            let URL_OPENER = `https://wa.me/${phoneNumber}`
            window.open(URL_OPENER, '_blank')
        } else {
            alert('Enter Number to continue!');
        }
    }

    return (
        <IonPage id="whatsappMessage-page">
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/utilities" disabled={false}  />
                    </IonButtons>
                    <IonTitle>Whatsapp Message</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className={'outerClass'}>
                    <div style={{ padding: 20 }}>
                        <div style={{ fontSize: 20, fontWeight: 'bold' }}>Whatsapp click to chat</div>
                        <p style={{ fontSize: 12 }}>WhatsApp's click to chat feature allows you to begin a chat with someone without having their phone number saved in your phone's address book.</p>
                        <PhoneInput
                            country={'in'}
                            value={phoneNumber}
                            onChange={(phone) => setPhoneNumber(phone)}
                        />
                        <div style={{ textAlign: 'left', marginTop: 15 }}>
                            <IonButton onClick={sayHello} shape="round" fill="outline">Click to chat</IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(WhatsappMessage);
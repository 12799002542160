import React, { useState } from 'react';
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonToast,
    IonPage,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonItem,
    IonInput,
    IonLabel, IonIcon
} from '@ionic/react';

import './Creativefonts.scss';
import 'react-phone-input-2/lib/style.css';
import { copyOutline } from 'ionicons/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { arrayStylermathBoldScript, arrayStylercircledNeg, arrayStylersquaredNeg, arrayStylersquared, arrayStylermathSansItalic, arrayStylermathSansBoldItalic, arrayStylermathSansBold, arrayStylermathSans, arrayStylermathMono, arrayStylermathBoldFraktur } from '../../util/fontContants';

interface CreativefontsProps { }
let i: any;
let TextmathBoldScriptSample = '𝓮𝓷𝓽𝓮𝓻 𝔂𝓸𝓾𝓻 𝓽𝓮𝔁𝓽 𝓪𝓫𝓸𝓿𝓮!';
let TextcircledNegSample = '🅔🅝🅣🅔🅡 🅨🅞🅤🅡 🅣🅔🅧🅣 🅐🅑🅞🅥🅔!';
let TextsquaredNegSample = '🅴🅽🆃🅴🆁 🆈🅾🆄🆁 🆃🅴🆇🆃 🅰🅱🅾🆅🅴!';
let TextsquaredSample = '🄴🄽🅃🄴🅁 🅈🄾🅄🅁 🅃🄴🅇🅃 🄰🄱🄾🅅🄴!';
let TextmathSansItalicSample = '𝘦𝘯𝘵𝘦𝘳 𝘺𝘰𝘶𝘳 𝘵𝘦𝘹𝘵 𝘢𝘣𝘰𝘷𝘦!';
let TextmathSansBoldItalicSample = '𝙚𝙣𝙩𝙚𝙧 𝙮𝙤𝙪𝙧 𝙩𝙚𝙭𝙩 𝙖𝙗𝙤𝙫𝙚!';
let TextmathSansBoldSample = '𝗲𝗻𝘁𝗲𝗿 𝘆𝗼𝘂𝗿 𝘁𝗲𝘅𝘁 𝗮𝗯𝗼𝘃𝗲!';
let TextmathSansSample = '𝖾𝗇𝗍𝖾𝗋 𝗒𝗈𝗎𝗋 𝗍𝖾𝗑𝗍 𝖺𝖻𝗈𝗏𝖾!';
let TextmathMonoSample = '𝚎𝚗𝚝𝚎𝚛 𝚢𝚘𝚞𝚛 𝚝𝚎𝚡𝚝 𝚊𝚋𝚘𝚟𝚎!';
let TextmathBoldFrakturSample = '𝖊𝖓𝖙𝖊𝖗 𝖞𝖔𝖚𝖗 𝖙𝖊𝖝𝖙 𝖆𝖇𝖔𝖛𝖊!';

const Creativefonts: React.FC<CreativefontsProps> = () => {
    const [text, setText] = useState('');
    const [showToast1, setShowToast1] = useState(false);
    const [TextmathBoldScript, setTextmathBoldScript] = useState('');
    const [TextcircledNeg, setTextcircledNeg] = useState('');
    const [TextsquaredNeg, setTextsquaredNeg] = useState('');
    const [Textsquared, setTextsquared] = useState('');
    const [TextmathSansItalic, setTextmathSansItalic] = useState('');
    const [TextmathSansBoldItalic, setTextmathSansBoldItalic] = useState('');
    const [TextmathSansBold, setTextmathSansBold] = useState('');
    const [TextmathSans, setTextmathSans] = useState('');
    const [TextmathMono, setTextmathMono] = useState('');
    const [TextmathBoldFraktur, setTextmathBoldFraktur] = useState('');


    //mathBoldScript
    const mathBoldScriptFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathBoldScript[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathBoldScript(arrayReturn.join(""));
    }
    //circledNeg
    const circledNegFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylercircledNeg[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextcircledNeg(arrayReturn.join(""));
    }

    //squaredNeg
    const squaredNegFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylersquaredNeg[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextsquaredNeg(arrayReturn.join(""));
    }

    //squared
    const squaredFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylersquared[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextsquared(arrayReturn.join(""));
    }

    //mathSansItalic
    const mathSansItalicFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathSansItalic[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathSansItalic(arrayReturn.join(""));
    }

    //mathSansBoldItalic
    const mathSansBoldItalicFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathSansBoldItalic[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathSansBoldItalic(arrayReturn.join(""));
    }

    //mathSansBold
    const mathSansBoldFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathSansBold[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathSansBold(arrayReturn.join(""));
    }

    //mathSans
    const mathSansFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathSans[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathSans(arrayReturn.join(""));
    }

    //mathMono
    const mathMonoFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathMono[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathMono(arrayReturn.join(""));
    }

    //mathBoldFraktur
    const mathBoldFrakturFontStyler = (dataVal: any) => {
        let arrayReturn: any = [];
        let stringData: any;
        for (i = 0; i < dataVal.length; i++) {
            stringData = arrayStylermathBoldFraktur[dataVal[i]];
            stringData ? arrayReturn.push(stringData) : arrayReturn.push(dataVal[i]);
        }
        setTextmathBoldFraktur(arrayReturn.join(""));
    }


    //Hits on user onChange text
    const changeTextValueIs = (dataVal: any) => {
        console.log(dataVal);
        mathBoldScriptFontStyler(dataVal.value);
        circledNegFontStyler(dataVal.value)
        squaredNegFontStyler(dataVal.value);
        squaredFontStyler(dataVal.value);
        mathSansItalicFontStyler(dataVal.value);
        mathSansBoldItalicFontStyler(dataVal.value);
        mathSansBoldFontStyler(dataVal.value);
        mathSansFontStyler(dataVal.value);
        mathMonoFontStyler(dataVal.value);
        mathBoldFrakturFontStyler(dataVal.value);
        setText(dataVal.value);
    }

    return (
        <IonPage id="creativefontse-page">
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/utilities" disabled={false} />
                    </IonButtons>
                    <IonTitle>Creative Fonts</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className={'outerClass'}>
                    <div style={{ paddingRight: 20, width: '100%' }}>
                        <div className={'inputText'}>
                            <IonItem style={{ border: 0 }}>
                                <IonLabel position="stacked">Write your text here</IonLabel>
                                <IonInput
                                    type={'text'}
                                    value={text}
                                    onIonChange={(textValue: any) => changeTextValueIs(textValue.detail)}
                                    className={'inputClassStyling'}
                                />
                            </IonItem>
                        </div>
                    </div>
                </div>
                <div className={'outerStylersList'}>
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathBoldScript ? TextmathBoldScript : TextmathBoldScriptSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathBoldScript}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>
                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextcircledNeg ? TextcircledNeg : TextcircledNegSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextcircledNeg}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextsquaredNeg ? TextsquaredNeg : TextsquaredNegSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextsquaredNeg}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathBoldFraktur ? TextmathBoldFraktur : TextmathBoldFrakturSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathBoldFraktur}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {Textsquared ? Textsquared : TextsquaredSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={Textsquared}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathSansItalic ? TextmathSansItalic : TextmathSansItalicSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathSansItalic}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathSansBoldItalic ? TextmathSansBoldItalic : TextmathSansBoldItalicSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathSansBoldItalic}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathSansBold ? TextmathSansBold : TextmathSansBoldSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathSansBold}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>
                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathSans ? TextmathSans : TextmathSansSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathSans}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                    <div className={'stylerListItem'}>
                        <div className={'itemLeftFontsList'}>
                            {TextmathMono ? TextmathMono : TextmathMonoSample}
                        </div>
                        <div className={'itemRightFontsList'}>
                            <CopyToClipboard text={TextmathMono}>
                                <IonIcon onClick={() => setShowToast1(true)} slot="start" icon={copyOutline} />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <hr />
                </div>
                <IonToast
                    mode={'md'}
                    isOpen={showToast1}
                    onDidDismiss={() => setShowToast1(false)}
                    message="Text Copied"
                    duration={300}
                />
            </IonContent>
        </IonPage >
    );
};

export default React.memo(Creativefonts);
import React, { useRef } from 'react'
import { IonHeader, IonToolbar, IonContent, IonPage, IonIcon, IonTitle, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import './Utilities.scss';
import { heartSharp } from 'ionicons/icons';
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react'
import { PluginListenerHandle, Plugins } from '@capacitor/core'


interface UtilitiesProps { }

const Utilities: React.FC<UtilitiesProps> = () => {

    const backButtonListenerHandle = useRef<PluginListenerHandle>()

    useIonViewDidEnter(() => {
        backButtonListenerHandle.current = Plugins.App.addListener(
            'backButton',
            Plugins.App.exitApp
        )
    })

    useIonViewDidLeave(() => {
        if (backButtonListenerHandle.current) {
            backButtonListenerHandle.current.remove()
            backButtonListenerHandle.current = undefined
        }
    })

    const openTTFWebsite = async () => {
        let URL_OPENER = `https://thetechnicalfist.com/`
        window.open(URL_OPENER, '_blank')
    }

    return (
        <IonPage id="utilities-page">
            <IonHeader>
                <IonToolbar>
                    {/* <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons> */}
                    <IonTitle>Ultimate Utilities</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className={'outerMainDiv'}>
                    <div className={'mainDivClassOuter'}>
                        <IonCard routerLink={`/utilities/whatsapp-message`} routerDirection={'forward'} className={'outerCionCardBgwhatsapplass'}>
                            <IonCardHeader>
                                <IonCardTitle>
                                    Whatsapp Chat Tool
                        </IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                            <div>Chat with anyone without saving number.</div>
                    </IonCardContent>
                        </IonCard>
                    </div>
                    <div className={'mainDivClassOuter'}>
                        <IonCard routerLink={`/utilities/creative-fonts`} routerDirection={'forward'} className={'outerionCardBgfontsclass'}>
                            <IonCardHeader>
                                <IonCardTitle>
                                    Creative Fonts Styling
                        </IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <div>丂ㄒㄚㄥ乇 your 𝚏𝚘𝚗𝚝𝚜, 🅒🅞🅟🅨 and 🅿🅰🆂🆃🅴.</div>
                    </IonCardContent>
                        </IonCard>
                    </div>
                </div>
                <div className={'madeWith'} onClick={openTTFWebsite}>
                    Made with <IonIcon icon={heartSharp} className={'iconHeartClass'} /> by The Technical Fist
                </div>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Utilities);
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
// import { loadConfData } from './data/sessions/sessions.actions';
// import { setIsLoggedIn, setUsername, loadUserData } from './data/user/user.actions';
// import { Schedule } from "./models/Schedule";
import Utilities from './pages/Utilities';
import WhatsappMessage from './pages/Whatsapp-message';
import Privacypolicy from './pages/PrivacyPolicy';
import Creativefonts from './pages/creativefonts/Creativefonts';

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  // schedule: Schedule;
}

interface DispatchProps {
  // loadConfData: typeof loadConfData;
  // loadUserData: typeof loadUserData;
  // setIsLoggedIn: typeof setIsLoggedIn;
  // setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode }) => {

  useEffect(() => {
    // loadUserData();
    // loadConfData();
    // eslint-disable-next-line
  }, []);

  return (
    // schedule.groups.length === 0 ? (
    //   <div></div>
    // ) : (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        {/* <IonSplitPane contentId="main"> */}
          {/* <Menu /> */}
          <IonRouterOutlet id="main" mode="ios">
            <Route exact path="/utilities" component={Utilities} />
            <Route path="/utilities/whatsapp-message" component={WhatsappMessage} />
            <Route path="/utilities/creative-fonts" component={Creativefonts} />
            <Route path="/utilities/privacy-policy" component={Privacypolicy} />
            <Redirect exact from="/" to="/utilities" />
          </IonRouterOutlet>
        {/* </IonSplitPane> */}
      </IonReactRouter>
    </IonApp>
    //     )
  )
}

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: false,
    // schedule: state.data.schedule
  }),
  // mapDispatchToProps: { loadConfData },
  component: IonicApp
});

import React from 'react';
import {
    IonHeader,
    IonToolbar,
    IonContent,
    IonPage,
    IonButtons,
    IonTitle,
    IonBackButton
} from '@ionic/react';
import './Whatsapp-message.scss';
import 'react-phone-input-2/lib/style.css'

interface PrivacypolicyProps { }

const Privacypolicy: React.FC<PrivacypolicyProps> = () => {

    return (
        <IonPage id="Privacypolicy-page">
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/utilities" disabled={false} />
                    </IonButtons>
                    <IonTitle>Privacy Policy</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ padding: 40 }}>
                    <h2>Privacy Policy</h2>
                    <p>Last updated: September 27, 2020</p>

                    <p>The Technical Fist (Utlitmate Utilities) provides this Privacy Policy to explain our policies and procedures. There is no collection of Personal Information that applies from your use of this website, located at https://utilities.thetechnicalfist.com/ (“Site”), as well as mobile app. This Privacy Policy is incorporated into and is subject to the The Technical Fist (Utlitmate Utilities) Terms of Service. This Privacy Policy also tells you about transparency of data with respect to your Personal Information, and how you can reach us to get answers to questions or doubt you may have about our privacy practices. Any collection of data will be mentioned here in this usage policy</p>

                    <h4>PERSONAL INFORMATION AND ITS USE:</h4>
                    <p>For the purpose of this Privacy Policy, “Personal Information” means any information relating to an identified or identifiable individual. We do not obtain Personal Information relating to you by any means. Even for analytics purpose there is no collection of data. Analytics maybe useful for us but when we implement that you will be updated.</p>

                    <h4>Customer support</h4>
                    <p>We may collect information through your direct communications (emails and phone calls) with our customer support team or other communications that you may send us and their contents. Nothing from app or website is transferred.</p>


                    <h4>Other</h4>
                    <p>We do not collect or practice into any data exchange when you provide them in the context of our customer, vendor, and partner relationships.</p>

                    <h4>Cookies and similar technologies</h4>
                    <p></p>Like many websites, we also use “cookie” technology to collect additional website usage data and to improve the Site and our Service. When you visit the Service, we may set some cookie for pages navigation, site loading etc purposes only. However, some features of the Service may not function properly if the ability to accept cookies is disabled.

<h4>Links to other websites</h4>
                    <p>Our Site contains links to other websites. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit Personal Information from you. Other sites follow different rules regarding the use or disclosure of the Personal Information you submit to them. We are not responsible for the content, privacy and security practices, and policies of third-party sites or services to which links or access are provided through the Service. We encourage you to read the privacy policies or statements of the other websites you visit.</p>

                    <h4>Compliance with laws and law enforcement</h4>
                    <p>The Technical Fist (Utlitmate Utilities) cooperates with government and law enforcement officials or private parties to enforce and comply with the law. To the extent permitted under applicable law, we may disclose any information about you to government or law enforcement officials or private parties as we believe is necessary or appropriate to investigate, respond to, and defend against claims, for legal process (including subpoenas), to protect the property and rights of The Technical Fist or a third party, to protect The Technical Fist (Utlitmate Utilities) against liability, for the safety of the public or any person, to prevent or stop any illegal, unethical, fraudulent, abusive, or legally actionable activity, to protect the security or integrity of the Service and any equipment used to make the Service available, or to comply with the law.
</p>

                    <h4>DATA TRANSFERS:</h4>
                    <p>No collection of data so no transfers applicable for now.</p>

                    <h4>CHILDREN’S POLICY:</h4>
                    <p>The Site is not directed to persons under 13 because it has tool that is for social media sites and children may not find it useful. If a parent or guardian has concern on usage that his or her child is tracked with personal information, they should contact us at thesheikhwasim@gmail.in for any clarifications. We do not collect any Personal Information from children under 13 or any other user. If we become aware that a child under 16 has provided us with Personal Information, we will delete such information from our files.</p>

                    <h4>UPDATES TO THIS PRIVACY POLICY:</h4>
                    <p>The Technical Fist (Utlitmate Utilities) may modify or update this Privacy Policy from time to time to reflect the changes in practices, and so you should review this page periodically. When we change the policy in a material manner we will let you know and update the ‘last modified’ date at the top of this agreement.</p>

                    <h4>Say Hello</h4>
                    <p>If you have any questions about this Privacy Policy or the Service, please contact us at thesheikhwasim@gmail.in</p>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Privacypolicy;
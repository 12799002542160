export const arrayStylermathBoldScript: any = {
    'a': '𝓪',
    'b': '𝓫',
    'c': '𝓬',
    'd': '𝓭',
    'e': '𝓮',
    'f': '𝓯',
    'g': '𝓰',
    'h': '𝓱',
    'i': '𝓲',
    'j': '𝓳',
    'k': '𝓴',
    'l': '𝓵',
    'm': '𝓶',
    'n': '𝓷',
    'o': '𝓸',
    'p': '𝓹',
    'q': '𝓺',
    'r': '𝓻',
    's': '𝓼',
    't': '𝓽',
    'u': '𝓾',
    'v': '𝓿',
    'w': '𝔀',
    'x': '𝔁',
    'y': '𝔂',
    'z': '𝔃',
    'A': '𝓪',
    'B': '𝓫',
    'C': '𝓬',
    'D': '𝓭',
    'E': '𝓮',
    'F': '𝓯',
    'G': '𝓰',
    'H': '𝓱',
    'I': '𝓲',
    'J': '𝓳',
    'K': '𝓴',
    'L': '𝓵',
    'M': '𝓶',
    'N': '𝓷',
    'O': '𝓸',
    'P': '𝓹',
    'Q': '𝓺',
    'R': '𝓻',
    'S': '𝓼',
    'T': '𝓽',
    'U': '𝓾',
    'V': '𝓿',
    'W': '𝔀',
    'X': '𝔁',
    'Y': '𝔂',
    'Z': '𝔃',
};

export const arrayStylercircledNeg: any = {
    'a': '🅐',
    'b': '🅑',
    'c': '🅒',
    'd': '🅓',
    'e': '🅔',
    'f': '🅕',
    'g': '🅖',
    'h': '🅗',
    'i': '🅘',
    'j': '🅙',
    'k': '🅚',
    'l': '🅛',
    'm': '🅜',
    'n': '🅝',
    'o': '🅞',
    'p': '🅟',
    'q': '🅠',
    'r': '🅡',
    's': '🅢',
    't': '🅣',
    'u': '🅤',
    'v': '🅥',
    'w': '🅦',
    'x': '🅧',
    'y': '🅨',
    'z': '🅩',
    'A': '🅐',
    'B': '🅑',
    'C': '🅒',
    'D': '🅓',
    'E': '🅔',
    'F': '🅕',
    'G': '🅖',
    'H': '🅗',
    'I': '🅘',
    'J': '🅙',
    'K': '🅚',
    'L': '🅛',
    'M': '🅜',
    'N': '🅝',
    'O': '🅞',
    'P': '🅟',
    'Q': '🅠',
    'R': '🅡',
    'S': '🅢',
    'T': '🅣',
    'U': '🅤',
    'V': '🅥',
    'W': '🅦',
    'X': '🅧',
    'Y': '🅨',
    'Z': '🅩'
}

export const arrayStylersquaredNeg: any = {
    'a': '🅰',
    'b': '🅱',
    'c': '🅲',
    'd': '🅳',
    'e': '🅴',
    'f': '🅵',
    'g': '🅶',
    'h': '🅷',
    'i': '🅸',
    'j': '🅹',
    'k': '🅺',
    'l': '🅻',
    'm': '🅼',
    'n': '🅽',
    'o': '🅾',
    'p': '🅿',
    'q': '🆀',
    'r': '🆁',
    's': '🆂',
    't': '🆃',
    'u': '🆄',
    'v': '🆅',
    'w': '🆆',
    'x': '🆇',
    'y': '🆈',
    'z': '🆉',
    'A': '🅰',
    'B': '🅱',
    'C': '🅲',
    'D': '🅳',
    'E': '🅴',
    'F': '🅵',
    'G': '🅶',
    'H': '🅷',
    'I': '🅸',
    'J': '🅹',
    'K': '🅺',
    'L': '🅻',
    'M': '🅼',
    'N': '🅽',
    'O': '🅾',
    'P': '🅿',
    'Q': '🆀',
    'R': '🆁',
    'S': '🆂',
    'T': '🆃',
    'U': '🆄',
    'V': '🆅',
    'W': '🆆',
    'X': '🆇',
    'Y': '🆈',
    'Z': '🆉',
}

export const arrayStylersquared: any = {
    'a': '🄰',
    'b': '🄱',
    'c': '🄲',
    'd': '🄳',
    'e': '🄴',
    'f': '🄵',
    'g': '🄶',
    'h': '🄷',
    'i': '🄸',
    'j': '🄹',
    'k': '🄺',
    'l': '🄻',
    'm': '🄼',
    'n': '🄽',
    'o': '🄾',
    'p': '🄿',
    'q': '🅀',
    'r': '🅁',
    's': '🅂',
    't': '🅃',
    'u': '🅄',
    'v': '🅅',
    'w': '🅆',
    'x': '🅇',
    'y': '🅈',
    'z': '🅉',
    'A': '🄰',
    'B': '🄱',
    'C': '🄲',
    'D': '🄳',
    'E': '🄴',
    'F': '🄵',
    'G': '🄶',
    'H': '🄷',
    'I': '🄸',
    'J': '🄹',
    'K': '🄺',
    'L': '🄻',
    'M': '🄼',
    'N': '🄽',
    'O': '🄾',
    'P': '🄿',
    'Q': '🅀',
    'R': '🅁',
    'S': '🅂',
    'T': '🅃',
    'U': '🅄',
    'V': '🅅',
    'W': '🅆',
    'X': '🅇',
    'Y': '🅈',
    'Z': '🅉',
}


export const arrayStylermathSansItalic: any = {
    'a': '𝘢',
    'b': '𝘣',
    'c': '𝘤',
    'd': '𝘥',
    'e': '𝘦',
    'f': '𝘧',
    'g': '𝘨',
    'h': '𝘩',
    'i': '𝘪',
    'j': '𝘫',
    'k': '𝘬',
    'l': '𝘭',
    'm': '𝘮',
    'n': '𝘯',
    'o': '𝘰',
    'p': '𝘱',
    'q': '𝘲',
    'r': '𝘳',
    's': '𝘴',
    't': '𝘵',
    'u': '𝘶',
    'v': '𝘷',
    'w': '𝘸',
    'x': '𝘹',
    'y': '𝘺',
    'z': '𝘻',
    'A': '𝘢',
    'B': '𝘣',
    'C': '𝘤',
    'D': '𝘥',
    'E': '𝘦',
    'F': '𝘧',
    'G': '𝘨',
    'H': '𝘩',
    'I': '𝘪',
    'J': '𝘫',
    'K': '𝘬',
    'L': '𝘭',
    'M': '𝘮',
    'N': '𝘯',
    'O': '𝘰',
    'P': '𝘱',
    'Q': '𝘲',
    'R': '𝘳',
    'S': '𝘴',
    'T': '𝘵',
    'U': '𝘶',
    'V': '𝘷',
    'W': '𝘸',
    'X': '𝘹',
    'Y': '𝘺',
    'Z': '𝘻',
}

export const arrayStylermathSansBoldItalic: any = {
    'a': '𝙖',
    'b': '𝙗',
    'c': '𝙘',
    'd': '𝙙',
    'e': '𝙚',
    'f': '𝙛',
    'g': '𝙜',
    'h': '𝙝',
    'i': '𝙞',
    'j': '𝙟',
    'k': '𝙠',
    'l': '𝙡',
    'm': '𝙢',
    'n': '𝙣',
    'o': '𝙤',
    'p': '𝙥',
    'q': '𝙦',
    'r': '𝙧',
    's': '𝙨',
    't': '𝙩',
    'u': '𝙪',
    'v': '𝙫',
    'w': '𝙬',
    'x': '𝙭',
    'y': '𝙮',
    'z': '𝙯',
    'A': '𝙖',
    'B': '𝙗',
    'C': '𝙘',
    'D': '𝙙',
    'E': '𝙚',
    'F': '𝙛',
    'G': '𝙜',
    'H': '𝙝',
    'I': '𝙞',
    'J': '𝙟',
    'K': '𝙠',
    'L': '𝙡',
    'M': '𝙢',
    'N': '𝙣',
    'O': '𝙤',
    'P': '𝙥',
    'Q': '𝙦',
    'R': '𝙧',
    'S': '𝙨',
    'T': '𝙩',
    'U': '𝙪',
    'V': '𝙫',
    'W': '𝙬',
    'X': '𝙭',
    'Y': '𝙮',
    'Z': '𝙯',
}

export const arrayStylermathSansBold: any = {
    'a': '𝗮',
    'b': '𝗯',
    'c': '𝗰',
    'd': '𝗱',
    'e': '𝗲',
    'f': '𝗳',
    'g': '𝗴',
    'h': '𝗵',
    'i': '𝗶',
    'j': '𝗷',
    'k': '𝗸',
    'l': '𝗹',
    'm': '𝗺',
    'n': '𝗻',
    'o': '𝗼',
    'p': '𝙥',
    'q': '𝗾',
    'r': '𝗿',
    's': '𝘀',
    't': '𝘁',
    'u': '𝘂',
    'v': '𝘃',
    'w': '𝘄',
    'x': '𝘅',
    'y': '𝘆',
    'z': '𝘇',
    'A': '𝗮',
    'B': '𝗯',
    'C': '𝗰',
    'D': '𝗱',
    'E': '𝗲',
    'F': '𝗳',
    'G': '𝗴',
    'H': '𝗵',
    'I': '𝗶',
    'J': '𝗷',
    'K': '𝗸',
    'L': '𝗹',
    'M': '𝗺',
    'N': '𝗻',
    'O': '𝗼',
    'P': '𝙥',
    'Q': '𝗾',
    'R': '𝗿',
    'S': '𝘀',
    'T': '𝘁',
    'U': '𝘂',
    'V': '𝘃',
    'W': '𝘄',
    'X': '𝘅',
    'Y': '𝘆',
    'Z': '𝘇',
}

export const arrayStylermathSans: any = {
    'a': '𝖺',
    'b': '𝖻',
    'c': '𝖼',
    'd': '𝖽',
    'e': '𝖾',
    'f': '𝖿',
    'g': '𝗀',
    'h': '𝗁',
    'i': '𝗂',
    'j': '𝗃',
    'k': '𝗄',
    'l': '𝗅',
    'm': '𝗆',
    'n': '𝗇',
    'o': '𝗈',
    'p': '𝗉',
    'q': '𝗊',
    'r': '𝗋',
    's': '𝗌',
    't': '𝗍',
    'u': '𝗎',
    'v': '𝗏',
    'w': '𝗐',
    'x': '𝗑',
    'y': '𝗒',
    'z': '𝗓',
    'A': '𝖺',
    'B': '𝖻',
    'C': '𝖼',
    'D': '𝖽',
    'E': '𝖾',
    'F': '𝖿',
    'G': '𝗀',
    'H': '𝗁',
    'I': '𝗂',
    'J': '𝗃',
    'K': '𝗄',
    'L': '𝗅',
    'M': '𝗆',
    'N': '𝗇',
    'O': '𝗈',
    'P': '𝗉',
    'Q': '𝗊',
    'R': '𝗋',
    'S': '𝗌',
    'T': '𝗍',
    'U': '𝗎',
    'V': '𝗏',
    'W': '𝗐',
    'X': '𝗑',
    'Y': '𝗒',
    'Z': '𝗓',
}

export const arrayStylermathMono: any = {
    'a': '𝚊',
    'b': '𝚋',
    'c': '𝚌',
    'd': '𝚍',
    'e': '𝚎',
    'f': '𝚏',
    'g': '𝚐',
    'h': '𝚑',
    'i': '𝚒',
    'j': '𝚓',
    'k': '𝚔',
    'l': '𝚕',
    'm': '𝚖',
    'n': '𝚗',
    'o': '𝚘',
    'p': '𝚙',
    'q': '𝚚',
    'r': '𝚛',
    's': '𝚜',
    't': '𝚝',
    'u': '𝚞',
    'v': '𝚟',
    'w': '𝚠',
    'x': '𝚡',
    'y': '𝚢',
    'z': '𝚣',
    'A': '𝚊',
    'B': '𝚋',
    'C': '𝚌',
    'D': '𝚍',
    'E': '𝚎',
    'F': '𝚏',
    'G': '𝚐',
    'H': '𝚑',
    'I': '𝚒',
    'J': '𝚓',
    'K': '𝚔',
    'L': '𝚕',
    'M': '𝚖',
    'N': '𝚗',
    'O': '𝚘',
    'P': '𝚙',
    'Q': '𝚚',
    'R': '𝚛',
    'S': '𝚜',
    'T': '𝚝',
    'U': '𝚞',
    'V': '𝚟',
    'W': '𝚠',
    'X': '𝚡',
    'Y': '𝚢',
    'Z': '𝚣',
}

export const arrayStylermathBoldFraktur: any = {
    'a': '𝖆',
    'b': '𝖇',
    'c': '𝖈',
    'd': '𝖉',
    'e': '𝖊',
    'f': '𝖋',
    'g': '𝖌',
    'h': '𝖍',
    'i': '𝖎',
    'j': '𝖏',
    'k': '𝖐',
    'l': '𝖑',
    'm': '𝖒',
    'n': '𝖓',
    'o': '𝖔',
    'p': '𝖕',
    'q': '𝖖',
    'r': '𝖗',
    's': '𝖘',
    't': '𝖙',
    'u': '𝖚',
    'v': '𝖛',
    'w': '𝖜',
    'x': '𝖝',
    'y': '𝖞',
    'z': '𝖟',
    'A': '𝖆',
    'B': '𝖇',
    'C': '𝖈',
    'D': '𝖉',
    'E': '𝖊',
    'F': '𝖋',
    'G': '𝖌',
    'H': '𝖍',
    'I': '𝖎',
    'J': '𝖏',
    'K': '𝖐',
    'L': '𝖑',
    'M': '𝖒',
    'N': '𝖓',
    'O': '𝖔',
    'P': '𝖕',
    'Q': '𝖖',
    'R': '𝖗',
    'S': '𝖘',
    'T': '𝖙',
    'U': '𝖚',
    'V': '𝖛',
    'W': '𝖜',
    'X': '𝖝',
    'Y': '𝖞',
    'Z': '𝖟'
}